import React from "react"

import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'

import Layout from '../../layouts/Website'
import Paper, { PaperClean } from '../../components/Paper'
import Heading from '../../components/Heading'

import Pannel from '../../views/web/Services/Pannel'

const useStyles = makeStyles((theme) => ({
  link: {
    color: '#fff',
    textDecoration: 'none',
    margin: '0px 0px 0px 0px'
  },
  linkTypo: {
    margin: '0px 0px 100px 0px'
  }
}));

const data = {
  consulting: {
    title: 'IT Consulting',
    rate: '$$$ | Daily Rate',
    options: [
      'Cyber Security',
      'Websites / Apps',
      'Front-end',
      'Back-end',
      'Systems Architecture',
    ]
  },
  development: {
    title: 'Software Development',
    rate: '$$ | Hourly Rate',
    options: [
      'Websites / Apps',
      'Custom Software',
      'Front-end',
      'Back-end',
    ],
  },
  system: {
    title: 'Custom System',
    rate: '$$$ | Plan + Daily Rate',
    options: [
      'Websites / Apps',
      'Custom Cloud Application',
      'Business Intelligence'
    ],
  },
  partnership: {
    title: 'Project Management',
    rate: '$$ | Daily Rate',
    options: [
      'Project Structuring',
      'Planning',
      'Task Management',
      'Efficiency Analysis'
    ],
  }
}

const IndexPage = () => {
  const classes = useStyles();
  return (
    <Layout>
        <Grid item xs={12}>
          <Paper>
            <Heading title='Software built in the Netherlands' />
          </Paper>
        </Grid>

        <Grid item xs={12}>
          <Paper>
            <Grid container>
              <Grid item xs={12} sm={6} lg={3}>
                <PaperClean>
                <Pannel data={data.consulting} />
                </PaperClean>
              </Grid>
              <Grid item xs={12} sm={6} lg={3}>
                <PaperClean>
                <Pannel data={data.partnership} />
                </PaperClean>
              </Grid>
              <Grid item xs={12} sm={6} lg={3}>
                <PaperClean>
                <Pannel data={data.development} />
                </PaperClean>
              </Grid>
              <Grid item xs={12} sm={6} lg={3}>
                <PaperClean>
                <Pannel data={data.system} />
                </PaperClean>
              </Grid>

            </Grid>
          </Paper>
        </Grid>

    </Layout>
  )
}

export default IndexPage
