import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Card from '@material-ui/core/Card'
import CardActions from '@material-ui/core/CardActions'
import CardContent from '@material-ui/core/CardContent'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'

const useStyles = makeStyles({
  root: {
    minWidth: 275,
    background: 'transparent'
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  pos: {
    marginBottom: 12,
  },
});

export default function OutlinedCard({data}) {
  const {title,rate,options} = data
  const classes = useStyles();
  const bull = <span className={classes.bullet}>•</span>;

  return (
    <Card className={classes.root} elevation={0}>
      <CardContent>
        <Typography variant='h6' component='h3'
        className={classes.title} gutterBottom
        align="left"
        >
          {title}
        </Typography>
        <Typography variant="subtitle2" component="h4"
          align="left"
        >
          {rate}
        </Typography>
        <ul>
        {options.map((obj, index) => {
          return <li key={index}>
          <Typography variant="body2" component="p"
            align="left"
          >
            {obj}
          </Typography>
          </li>
        })}
        </ul>
      </CardContent>
    </Card>
  );
}
